import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import './App.css';


function App() { 

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <div style={{margin: '200px'}}>
      <Grid container spacing={2}>
        <Grid item xs={4} md={2} style={{marginRight: '50px'}}>
          <Item style={{textAlign: 'center', height: '200px'}}>
            <div style={{marginTop: '50px'}}>
              <div><b>MPGS SSO</b></div>
              <br />
              <div>
                <a href='https://google.com' target='_blank'>
                  <Button size='small' variant="contained" color="success">Success</Button>
                </a>
                <br /><br />
                <a href='https://google.com' target='_blank'>
                  <Button size='small' variant="contained" color="error">Failed</Button>
                </a>
              </div>
            </div>
          </Item>
        </Grid>
        <Grid item xs={4} md={2} style={{marginRight: '50px'}}>
        <Item style={{textAlign: 'center', height: '200px'}}>
          <div style={{marginTop: '50px'}}>
            <div><b>HP</b></div>
            <br />
            <div>
              <a href='https://retailer.dev.optty.com/signin-partner/test44@test.test' target='_blank'>
                <Button size='small' variant="contained" color="success">Success</Button>
              </a>
              <br /><br />
              <a href='https://retailer.dev.optty.com/signin-partner/user5@test.com' target='_blank'>
                <Button size='small' variant="contained" color="error">Failed</Button>
              </a>
            </div>
          </div>
          </Item>
        </Grid>
        <Grid item xs={4} md={2} style={{marginRight: '50px'}}>
        <Item style={{textAlign: 'center', height: '200px'}}>
          <div style={{marginTop: '50px'}}>
            <div><b>VISA</b></div>
            <br />
            <div>
              <a href='https://google.com' target='_blank'>
                <Button size='small' variant="contained" color="success">Success</Button>
              </a>
              <br /><br />
              <a href='https://google.com' target='_blank'>
                <Button size='small' variant="contained" color="error">Failed</Button>
              </a>
            </div>
          </div>
          </Item>
        </Grid>
        {/* <Grid item xs={4} md={2} style={{marginRight: '50px'}}> 
        <Item style={{textAlign: 'center', height: '200px'}}>
          <div style={{marginTop: '50px'}}>
            <div><b>MPGS</b></div>
            <br />
            <div>
              <a href='https://google.com' target='_blank'>
                <Button size='small' variant="contained" color="success">Success</Button>
              </a>
              <br /><br />
              <a href='https://google.com' target='_blank'>
                <Button size='small' variant="contained" color="error">Failed</Button>
              </a>
            </div>
          </div>
          </Item>
        </Grid> */}
      </Grid>
    </div>
  );
}

export default App;
